import * as React from 'react'

function LinkedinIcon(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 0C11.2311 0 8.52431 0.821086 6.22202 2.35943C3.91973 3.89777 2.12532 6.08427 1.06569 8.64243C0.00606595 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809206 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C28 10.287 26.525 6.72601 23.8995 4.10051C21.274 1.475 17.713 0 14 0V0ZM9.92896 21.3684H6.87474V11.5297H9.92896V21.3684ZM8.40001 10.1868C8.04851 10.1883 7.70447 10.0855 7.41137 9.89147C7.11828 9.69743 6.88929 9.42086 6.75335 9.09671C6.61741 8.77256 6.58062 8.41538 6.64763 8.07033C6.71465 7.72527 6.88245 7.40783 7.12984 7.15812C7.37723 6.90842 7.6931 6.73766 8.03751 6.66743C8.38193 6.59721 8.73943 6.63067 9.06484 6.76358C9.39024 6.89649 9.66894 7.12289 9.8657 7.41416C10.0625 7.70544 10.1684 8.0485 10.1703 8.4C10.1713 8.87134 9.98559 9.32389 9.65386 9.65872C9.32213 9.99356 8.87133 10.1834 8.40001 10.1868ZM21.3684 21.3684H18.3161V16.5789C18.3161 15.4368 18.294 13.9724 16.7282 13.9724C15.1624 13.9724 14.8861 15.2139 14.8861 16.4961V21.3684H11.8447V11.5297H14.7755V12.8708H14.8179C15.225 12.0971 16.2216 11.2811 17.7082 11.2811C20.7992 11.2811 21.3684 13.3184 21.3684 15.9637V21.3684Z"
        fill="white"
      />
    </svg>
  )
}
export default LinkedinIcon
