import * as React from 'react'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import { ClickAwayListener, Grid, MenuList, Paper, Popper } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import Flags from 'country-flag-icons/react/3x2'
import { useTranslation } from 'next-i18next'
import NextLink from '@imports/NextLink'
import { useRouter } from 'next/router'

function LanguageSelect({ containerProps = {} }) {
  const router = useRouter()
  const { i18n } = useTranslation()
  const languages = i18n.options?.languages ?? []
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((previousOpen) => !previousOpen)
  }

  const selectedLanguage = languages.find(({ locale }) => locale === i18n.language) ?? {}
  const FlagComponent = Flags[selectedLanguage.country ?? 'US']

  const handleLanguageChange = (locale) => {
    router.replace(
      {
        pathname: router.pathname,
        query: router.query,
      },
      undefined,
      {
        locale,
      }
    )
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems="center"
        underline="none"
        onClick={handleClick}
        {...containerProps}
        sx={{
          ':hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Grid item>
          <FlagComponent width={19} />
        </Grid>
        <Grid item>
          <Typography
            // fontFamily="var(--cairo-font)"
            variant="caption"
            color="inherit"
          >
            {selectedLanguage.name ?? 'English'}
          </Typography>
        </Grid>
        <Grid item>
          <ArrowDropDown
            fontSize="small"
            sx={{ color: 'inherit', mt: 0.8, ml: -0.5 }}
          />
        </Grid>
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        sx={{ zIndex: 1000 }}
        disablePortal
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Paper sx={{ zIndex: 1000 }}>
            <MenuList>
              {languages.map(({ locale, name, country }) => {
                const Flag = Flags[country]
                return (
                  <MenuItem
                    key={locale}
                    value="english"
                    dense
                    onClick={(e) => {
                      e.preventDefault()
                      // i18n.changeLanguage(locale)
                      setOpen(false)
                      handleLanguageChange(locale)
                    }}
                    component={NextLink}
                    href={`/${locale}${router.asPath}`}
                    locale={false}
                  >
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item>{!!Flag && <Flag width={19} />}</Grid>
                      <Grid item>
                        <Typography
                          // fontFamily="var(--cairo-font)"
                          variant="caption"
                          color="inherit"
                        >
                          {name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                )
              })}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export default LanguageSelect
