import * as React from 'react'

function SVGComponent(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 0a14 14 0 1 0 0 28 14 14 0 0 0 0-28Zm3.983 9.111c0 .263-.11.368-.369.368-.495 0-.99 0-1.485.02-.493.021-.762.246-.762.761-.011.553 0 1.095 0 1.658h2.12c.302 0 .405.104.405.408v2.221c0 .3-.095.394-.4.396h-2.144v5.994c0 .32-.099.422-.416.422h-2.306c-.278 0-.387-.109-.387-.387v-6.02h-1.831c-.287 0-.39-.105-.39-.394v-2.234c0-.288.108-.396.392-.396h1.829v-1.612a4.032 4.032 0 0 1 .503-2.074c.365-.64.95-1.126 1.645-1.367a4.037 4.037 0 0 1 1.41-.243h1.81c.26 0 .368.114.368.368.01.71.01 1.411.008 2.111Z"
        fill="#fff"
      />
    </svg>
  )
}
export default SVGComponent
