import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Logo from '@assets/logo.png'
import { Grid } from '@mui/material'
import Image from 'next/image'
import useScrollBounds from '@utils/useScrollBounds'
import LanguageSelect from '@imports/LanguageSelect'
import NextLink from '@imports/NextLink'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import LogoWhite from '@assets/logo-white.png'
import GetStartedButton from '@imports/GetStartedButton'
import MobileDrawer from './MobileDrawer'

const links = [
  {
    label: 'pricing',
    key: 'pricing',
    url: '/pricing',
  },
  {
    label: 'blog',
    key: 'blog',
    url: '/blog',
  },
  {
    label: 'help center',
    key: 'help_center',
    url: '/help-center',
  },
  {
    label: 'contact us',
    key: 'contact_us',
    url: '#contact-us',
  },
  // {
  //   label: 'about us',
  //   key: 'about_us',
  //   url: '/#about-us',
  // },
]

function Navbar({ dark = false }) {
  const visible = useScrollBounds(2, 50) // visible (0, 2), threshold (2, 52), not visible (52, infinity)
  const { t } = useTranslation(['navbar', 'common'])
  const { asPath } = useRouter()
  const linksLocalized = links.map((link) => ({
    ...link,
    label: t(`navbar:${link.key}`) || link.label,
  }))
  return (
    <AppBar
      elevation={visible ? 0 : 1}
      position="fixed"
      sx={{
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: visible ? (dark ? 'primary.main' : 'transparent') : 'white',
        color: visible && dark ? 'white' : 'black',
        transition: (theme) =>
          theme.transitions.create(['background-color', 'box-shadow'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          transition: (theme) =>
            theme.transitions.create(['height'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          height: visible ? 88 : 55,
        }}
      >
        <Toolbar
          sx={{ height: '100%', minHeight: '55px !important' }}
          disableGutters
        >
          <NextLink
            href="/"
            aria-label={t('common:home_page')}
          >
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                mr: 5,
                height: visible ? 59 : 40,
                transition: (theme) =>
                  theme.transitions.create(['box-shadow'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
              }}
            >
              <Image
                style={{
                  height: '100%',
                  width: 'auto',
                }}
                // layout="responsive"
                alt={t('common:blue_assembly_logo')}
                src={visible && dark ? LogoWhite : Logo}
              />
            </Box>
          </NextLink>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, flexGrow: 1 }}>
            <NextLink
              href="/"
              aria-label={t('common:home_page')}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Image
                height={30}
                alt={t('common:blue_assembly_logo')}
                src={visible && dark ? LogoWhite : Logo}
              />
            </NextLink>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
            <Grid
              container
              spacing={3}
            >
              {linksLocalized.map(({ label, url }) => (
                <Grid
                  item
                  key={label}
                >
                  <Typography
                    component="h1"
                    // fontFamily="var(--cairo-font)"
                    variant="subtitle2"
                    fontWeight={500}
                    sx={{
                      my: 2,
                      color: asPath === url ? 'secondary.main' : 'inherit',
                      transition: (theme) =>
                        theme.transitions.create(['color'], {
                          easing: theme.transitions.easing.sharp,
                          duration: theme.transitions.duration.leavingScreen,
                        }),
                      ':hover': {
                        color: 'secondary.main',
                      },
                    }}
                    textTransform="capitalize"
                  >
                    <NextLink
                      href={url}
                      scroll={false}
                      aria-label={label}
                    >
                      {label}
                    </NextLink>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Grid
              container
              spacing={2}
              alignItems="center"
            >
              <Grid item>
                <LanguageSelect containerProps={{ sx: { color: '#000' } }} />
              </Grid>
              <Grid item>
                <Typography
                  component="h1"
                  // fontFamily="var(--cairo-font)"
                  variant="subtitle2"
                  fontWeight={500}
                  sx={{
                    my: 2,
                    transition: (theme) =>
                      theme.transitions.create(['color'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                      }),
                    ':hover': {
                      color: 'secondary.main',
                    },
                  }}
                >
                  <NextLink
                    href="/login"
                    scroll={false}
                    aria-label={t('common:login_page')}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {t('common:login')}
                  </NextLink>
                </Typography>
              </Grid>
              <Grid item>
                <GetStartedButton
                  id="get-started-button"
                  label={t('common:get_started')}
                  size={visible ? 'medium' : 'small'}
                  sx={{
                    transition: (theme) =>
                      theme.transitions.create(['padding'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                      }),
                  }}
                  color={visible && dark ? 'paper' : 'primary'}
                />
              </Grid>
              <Grid item>
                <Typography
                  color={dark && visible ? 'white' : 'primary.dark'}
                  component="h1"
                  // fontFamily="var(--cairo-font)"
                  variant="subtitle2"
                  fontWeight={600}
                  sx={{
                    my: 2,
                    ml: 2,
                    transition: (theme) =>
                      theme.transitions.create(['color'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                      }),
                    ':hover': {
                      color: 'secondary.main',
                    },
                  }}
                >
                  <NextLink
                    href="https://meetings-eu1.hubspot.com/fatimah"
                    target="_blank"
                    scroll={false}
                    aria-label={t('common:Request_a_Demo')}
                    style={{ textDecoration: 'underline' }}
                  >
                    {t('common:Request_a_Demo')}
                  </NextLink>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <MobileDrawer
              dark={visible && dark}
              links={linksLocalized}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Navbar
