import React from 'react'
import Link from 'next/link'

function NextLink({ children, style = {}, ...props }) {
  return (
    <Link
      style={{ textDecoration: 'none', color: 'inherit', ...style }}
      {...props}
    >
      {children}
    </Link>
  )
}

export default NextLink
