import React from 'react'
import useScrollPosition from './useScrollPosition'

const useScrollBounds = (lowerBound, threshold) => {
  const upperBound = lowerBound + threshold
  const scrollPosition = useScrollPosition()
  const [visible, setVisible] = React.useState(true)
  React.useEffect(() => {
    if (!(scrollPosition > lowerBound && scrollPosition < upperBound)) {
      setVisible(scrollPosition < lowerBound)
    }
  }, [lowerBound, upperBound, scrollPosition])
  return visible
}

export default useScrollBounds
