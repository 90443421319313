import React from 'react'
import NextLink from '@imports/NextLink'
import ResponsiveButton from '@components/ResponsiveButton'

function GetStartedButton({ label = '', ...buttonProps }) {
  return (
    <NextLink
      href="/register"
      aria-label={label}
    >
      <ResponsiveButton
        disableElevation
        variant="contained"
        aria-label={label}
        {...buttonProps}
      >
        {label}
      </ResponsiveButton>
    </NextLink>
  )
}
export default GetStartedButton
