import * as React from 'react'

function PlayStoreSvg(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g
        clipPath="url(#a)"
        fill="inherit"
      >
        <path d="M4.365.32a2.54 2.54 0 0 0-2.478.006l9.112 8.406 3.06-3.061L4.366.32Zm-3.39.866a2.33 2.33 0 0 0-.35 1.221V17.59c0 .427.117.835.327 1.191l9.163-9.163-9.14-8.431ZM18.118 7.91l-2.92-1.61-3.28 3.28 4.02 3.708 2.181-1.204c.785-.435 1.255-1.215 1.255-2.087 0-.873-.47-1.653-1.256-2.086Zm-7.084 2.554-9.189 9.189c.4.227.837.347 1.277.347.425 0 .852-.107 1.243-.324l10.421-5.75-3.752-3.462Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="inherit"
            d="M0 0h20v20H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default PlayStoreSvg
