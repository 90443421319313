import React from 'react'
import { Button, useMediaQuery, useTheme } from '@mui/material'

function ResponsiveButton({ children, size = 'medium', ...props }) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const buttonProps = {
    size: isSmallScreen ? 'small' : size,
  }
  return (
    <Button
      {...buttonProps}
      {...props}
    >
      {children}
    </Button>
  )
}

export default ResponsiveButton
