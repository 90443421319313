import * as React from 'react'
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import LogoWhite from '@assets/logo-white.png'
import Image from 'next/image'
import NextLink from '@imports/NextLink'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import GetStartedButton from '@imports/GetStartedButton'
import LanguageSelect from '@imports/LanguageSelect'

function MobileDrawer({ links, dark = false }) {
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation(['navbar', 'common'])
  const { asPath } = useRouter()
  return (
    <div>
      <GetStartedButton
        variant="text"
        size="small"
        sx={{
          mr: 1,
          transition: (theme) =>
            theme.transitions.create(['padding'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
        aria-label={t('common:get_started_free')}
        color={dark ? 'paper' : 'primary'}
        label={t('common:get_started')}
      />
      <IconButton
        aria-label="open drawer"
        onClick={() => setOpen(true)}
        color={dark ? 'paper' : 'inherit'}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { backgroundColor: 'primary.dark', color: 'white', borderRadius: 0 },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 3 }}>
          <NextLink
            href="/"
            onClick={() => setOpen(false)}
            aria-label={t('common:home_page')}
          >
            <Image
              style={{
                width: 150,
                height: '100%',
                objectFit: 'contain',
              }}
              alt={t('common:blue_assembly_logo_white')}
              src={LogoWhite}
            />
          </NextLink>
        </Box>
        <Box sx={{ width: 250, display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box sx={{ flex: 1 }}>
            <List>
              {links.map(({ label, url }, index) => (
                <ListItem
                  key={index}
                  disablePadding
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      backgroundColor: url === asPath ? 'secondary.main' : '',
                      ':hover': {
                        backgroundColor: 'secondary.main',
                      },
                      px: 2.5,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ color: 'inherit' }}
                      textTransform="capitalize"
                    >
                      <NextLink
                        href={url}
                        onClick={() => setOpen(false)}
                        aria-label={label}
                      >
                        {label}
                      </NextLink>
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider sx={{ borderColor: '#FFFFFF88' }} />
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: 'inherit' }}
                    textTransform="capitalize"
                  >
                    <NextLink
                      href="https://meetings-eu1.hubspot.com/fatimah"
                      target="_blank"
                      onClick={() => setOpen(false)}
                      aria-label={t('common:Request_a_Demo')}
                    >
                      {t('common:Request_a_Demo')}
                    </NextLink>
                  </Typography>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: 'inherit' }}
                    textTransform="capitalize"
                  >
                    <NextLink
                      href="/login"
                      onClick={() => setOpen(false)}
                      aria-label={t('common:login_page')}
                    >
                      {t('common:login')}
                    </NextLink>
                  </Typography>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: 'inherit' }}
                    textTransform="capitalize"
                  >
                    <NextLink
                      href="/register"
                      onClick={() => setOpen(false)}
                      aria-label={t('common:get_started_free')}
                    >
                      {t('common:get_started')}
                    </NextLink>
                  </Typography>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
          <Box py={2}>
            <Grid
              container
              justifyContent="center"
            >
              <Grid item>
                <LanguageSelect />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </div>
  )
}

export default MobileDrawer
