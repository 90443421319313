import React from 'react'
import { Box, Container, Grid, IconButton, Link, Typography } from '@mui/material'
import FacebookIcon from '@assets/icons/FacebookIcon'
import LinkedinIcon from '@assets/icons/LinkedinIcon'
import TwitterIcon from '@assets/icons/TwitterIcon'
import InstagramIcon from '@assets/icons/InstagramIcon'
import LogoWhite from '@assets/logo-white.png'
import Image from 'next/image'
import LanguageSelect from '@imports/LanguageSelect'
import NextLink from '@imports/NextLink'
import { useTranslation } from 'next-i18next'
import ResponsiveButton from '@components/ResponsiveButton'
import PlayStoreSvg from '@assets/icons/PlayStoreSvg'
import AppStoreSvg from '@assets/icons/AppStoreSvg'

const sections = [
  {
    title: 'business',
    subsections: [
      {
        title: 'privacy_policy',
        href: '/privacy-policy',
      },
      {
        title: 'terms_of_services',
        href: '/terms-and-conditions',
      },
      {
        title: 'affiliate_program',
        href: '#',
      },
      {
        title: 'careers',
        href: '#',
      },
    ],
  },
  {
    title: 'information',
    subsections: [
      {
        title: 'press_and_media',
        href: '#',
      },
      {
        title: 'investors',
        href: '#',
      },
      {
        title: 'blog',
        href: '/blog',
      },
    ],
  },
  {
    title: 'help',
    subsections: [
      {
        title: 'book_a_demo',
        href: 'https://meetings-eu1.hubspot.com/fatimah',
        typoProps: {
          fontWeight: 600,
          style: { textDecoration: 'underline' },
        },
      },
      {
        title: 'support',
        href: '/help-center',
      },
      {
        title: 'contact_us',
        href: '#contact-us',
      },
    ],
  },
]
export function Footer() {
  const { t } = useTranslation(['footer'])
  return (
    <Box
      py={7}
      sx={{ backgroundColor: '#24426A', color: 'primary.contrastText' }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={6}
        >
          {sections.map(({ title, subsections }, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={3}
            >
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    fontWeight={700}
                  >
                    {t(`footer:${title}`)}
                  </Typography>
                </Grid>
                {subsections.map((subsection, idx) => (
                  <Grid
                    key={idx}
                    item
                    xs={12}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={400}
                      sx={{
                        ':hover': { color: 'secondary.main' },
                        fontSize: { xs: '15px !important', md: '20px !important' },
                      }}
                      {...(subsection.typoProps ?? {})}
                    >
                      <NextLink href={subsection.href}>{t(`footer:${subsection.title}`)}</NextLink>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Box mb={4}>
              <Typography
                display="block"
                gutterBottom
                variant="h6"
                fontWeight={700}
              >
                {t(`footer:Follow_Us`)}
              </Typography>
              <IconButton
                component={Link}
                href="https://www.facebook.com/blueassembly"
                target="_blank"
                aria-label="facebook"
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                component={Link}
                href="https://www.linkedin.com/company/blue-assembly/"
                target="_blank"
                aria-label="linkedin"
              >
                <LinkedinIcon />
              </IconButton>
              <IconButton
                component={Link}
                href="https://twitter.com/blueassembly"
                target="_blank"
                aria-label="twitter"
              >
                <TwitterIcon />
              </IconButton>
              <IconButton
                component={Link}
                href="https://www.instagram.com/blueassemblysa/"
                target="_blank"
                aria-label="instagram"
              >
                <InstagramIcon
                  width={28}
                  height={28}
                  style={{ backgroundColor: 'white', borderRadius: '50%' }}
                />
              </IconButton>
            </Box>
            <Box>
              <Typography
                display="block"
                gutterBottom
                variant="h6"
                fontWeight={700}
              >
                {t(`footer:Download_the_App`)}
              </Typography>
              <ResponsiveButton
                variant="contained"
                disableElevation
                sx={{ fontWeight: 500, mb: 1, width: 200, px: 0 }}
                size="small"
                color="store"
                component="a"
                href="https://play.google.com/store/apps/details?id=com.blueassembly"
                target="_blank"
                aria-label="google play"
              >
                <Box py={0.5}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="flex-end"
                  >
                    <Grid
                      item
                      sx={{ lineHeight: 1 }}
                    >
                      <PlayStoreSvg
                        width={32}
                        height={32}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="caption"
                        fontSize={13}
                        display="block"
                        lineHeight={1}
                        sx={{ pb: 0.5 }}
                      >
                        GET IT ON
                      </Typography>
                      <Typography
                        lineHeight={1}
                        display="block"
                      >
                        Google Play
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </ResponsiveButton>
              <ResponsiveButton
                variant="contained"
                disableElevation
                sx={{ fontWeight: 500, width: 200, px: 0 }}
                size="small"
                color="store"
                component="a"
                href="https://apps.apple.com/eg/app/blue-assembly/id1591786196"
                target="_blank"
                aria-label="app store"
              >
                <Box py={0.5}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="flex-end"
                  >
                    <Grid
                      item
                      sx={{ lineHeight: 1 }}
                    >
                      <AppStoreSvg
                        fill="white"
                        width={32}
                        height={32}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="caption"
                        fontSize={13}
                        display="block"
                        lineHeight={1}
                        sx={{ pb: 0.5 }}
                      >
                        Download on the
                      </Typography>
                      <Typography
                        lineHeight={1}
                        display="block"
                      >
                        App store
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </ResponsiveButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container
        sx={{ mt: 14 }}
        maxWidth="xl"
      >
        <Grid
          container
          spacing={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Image
              loading="lazy"
              height={40}
              width={105}
              alt="Blue Assembly logo white"
              src={LogoWhite}
            />
          </Grid>
          <Grid
            item
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <Typography
              variant="body1"
              fontWeight={700}
            >
              {t('footer:all_rights_reserved')}
            </Typography>
          </Grid>
          <Grid item>
            <LanguageSelect containerProps={{ sx: { color: 'white' } }} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <Typography
              variant="body1"
              fontWeight={700}
              textAlign="center"
            >
              {t('footer:all_rights_reserved')}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer
