import * as React from 'react'

function TwitterIcon(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 0C6.27 0 0 6.27 0 14s6.27 14 14 14 14-6.27 14-14S21.73 0 14 0Zm6.392 10.916c.006.137.01.276.01.415 0 4.246-3.233 9.143-9.143 9.143a9.096 9.096 0 0 1-4.926-1.444 6.448 6.448 0 0 0 4.758-1.331 3.217 3.217 0 0 1-3.002-2.232 3.198 3.198 0 0 0 1.451-.055 3.214 3.214 0 0 1-2.578-3.15v-.04a3.2 3.2 0 0 0 1.456.401 3.21 3.21 0 0 1-.995-4.29 9.123 9.123 0 0 0 6.624 3.358 3.214 3.214 0 0 1 5.476-2.93 6.445 6.445 0 0 0 2.04-.78c-.24.75-.75 1.379-1.413 1.777a6.41 6.41 0 0 0 1.845-.506 6.53 6.53 0 0 1-1.603 1.664Z"
        fill="#fff"
      />
    </svg>
  )
}
export default TwitterIcon
