import React from 'react'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material'
import { Fab } from '@mui/material'

export function ScrollTopButton() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 150,
  })

  const handleClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 10 }}
      >
        <Fab
          size="small"
          aria-label="scroll back to top"
          color="primary"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Fade>
  )
}

export default ScrollTopButton
