import React from 'react'
import Navbar from '@imports/Navbar'
import Footer from '@imports/Footer'
import ScrollTopButton from '@components/ScrollTopButton'
import { Box } from '@mui/material'

function MainLayout({ children, navbarProps = {} }) {
  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Navbar {...navbarProps} />
      <Box sx={{ pt: 11, backgroundColor: navbarProps.dark ? 'primary.main' : 'transparent' }} />
      <Box>
        {children}
        <ScrollTopButton />
      </Box>
      <Footer />
    </Box>
  )
}

export default MainLayout
