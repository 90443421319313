import * as React from 'react'

function AppStoreSvg(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#a)"
        fill="inherit"
      >
        <path d="M15.48 11.21c-.55-2.768 2.078-4.392 2.078-4.392s-1.024-1.481-2.745-1.862c-1.72-.382-2.58.095-3.44.453-.86.359-1.289.359-1.289.359-1.24 0-2.148-1.315-4.512-.716-1.63.41-3.344 2.291-3.654 4.536-.31 2.245.359 5.11 1.648 7.33C4.855 19.14 6.168 19.975 7.1 20c.932.024 1.863-.667 2.984-.836 1.123-.166 1.814.407 2.915.717 1.097.31 1.481.022 2.745-1.052 1.267-1.075 2.413-4.157 2.413-4.157s-2.127-.69-2.677-3.46Zm-2.436-8.046C14.264 1.923 13.85 0 13.85 0s-1.71.3-2.858 1.493c-1.145 1.194-.956 3.08-.956 3.08s1.79-.167 3.008-1.409Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="inherit"
            d="M0 0h20v20H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default AppStoreSvg
